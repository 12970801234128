import React from 'react';

import Layout from '../components/Layout';
import Link from '../components/Link';

const IndexPage = () => (
  <Layout>
    <section id="thanks" className="main">
      <div className="container">
        <div className="content">
          <header className="major">
            <h2>Thanks</h2>
          </header>
          <section>
            <h4>Thanks for getting in touch! I'll get back to you as soon as I can.</h4>
            <Link href="/" className="icon alt fa-arrow-left"> Back</Link>
          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
